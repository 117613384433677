import { Fragment, useState } from 'react';
import moment from 'moment';
import QRCode from 'qrcode.react';
import MobilekeyMenual from './MobilekeyMenual';
import CheckOut from './CheckOut';

const BookingList = ({ bookingItem, qrCode, checkOut }: any) => {
  const [isOpenModalMobilekeyMenual, setIsOpenModalMobilekeyMenual] = useState(false);
  const [isOpenModalCheckOut, setIsOpenModalCheckOut] = useState(false);

  const openModalMobilekeyMenual = () => {
    setIsOpenModalMobilekeyMenual(true);
  };

  const closeModalMobilekeyMenual = () => {
    setIsOpenModalMobilekeyMenual(false);
  };

  const openModalCheckout = () => {
    setIsOpenModalCheckOut(true);
  };

  const closeModalCheckOut = () => {
    setIsOpenModalCheckOut(false);
  };

  const dayFormatter = (day: string) => {
    return (
      {
        Sun: '일',
        Mon: '월',
        Tue: '화',
        Wed: '수',
        Thu: '목',
        Fri: '금',
        Sat: '토',
      }[day] || day
    );
  };

  const setBookingPeriod = (date: string | Date) => {
    return `${moment(date).format('YYYY.MM.DD')} (${dayFormatter(moment(date).format('ddd'))})`;
  };

  return (
    <Fragment>
      <div className="checked-in-background-container">
        <div className="opacity-background">
          <span className="welcome-message">
            {bookingItem.guestName} 고객님,
            <br />
            환영합니다.
          </span>
          <div className="booking-id-container">
            <span className="booking-id-label">예약번호</span>
            <span className="booking-id-value">{bookingItem.rsvnNo}</span>
          </div>
        </div>
      </div>
      <div className="checked-in-booking-card-container">
        <span className="hotel-name">
          {bookingItem.propertyName} {bookingItem.zoneTypeName}
        </span>
        <span className="room-name">{bookingItem.roomNo}호</span>
        <div className="horizontal-line mt-15 mb-20"></div>
        <div className="userkey-container">
          <div className="userkey-item mb-15">
            <span className="userkey-item-label">객실 비밀번호</span>
            <span className="userkey-item-value">{bookingItem.pincode ? `${bookingItem.pincode}*` : '-'}</span>
          </div>
          <div className="userkey-item">
            <span className="userkey-item-label">
              모바일키
              <img
                className="tip-icon ml-2 mb-4"
                src="../../assets/images/question-mark.png"
                alt="icon"
                onClick={openModalMobilekeyMenual}
              />
            </span>
            <span className="userkey-item-value">{bookingItem.exchangekey ? bookingItem.exchangekey : '-'}</span>
          </div>
        </div>

        <div className="qrCode-container">
          {qrCode ? (
            <>
              <span>공용 도어 QR</span>
              <QRCode value={qrCode} size={180} />
            </>
          ) : (
            <>
              <span>공용 도어 QR 코드가 없습니다.</span>
              <button onClick={() => window.location.reload()}>새로고침</button>
            </>
          )}
        </div>

        <div className="checked-in-booking-period-container">
          <img className="period-icon mt-2" src="../../assets/images/period.png" alt="icon" />
          <div className="period-item-container">
            <div className="period-item mb-8">
              <span className="period-item-label">체크인</span>
              <span className="period-item-value">{bookingItem.arrvDate ? `${setBookingPeriod(bookingItem.arrvDate)} 16:00` : '-'}</span>
            </div>
            <div className="period-item">
              <span className="period-item-label">체크아웃</span>
              <span className="period-item-value">{bookingItem.deptDate ? `${setBookingPeriod(bookingItem.deptDate)} 11:00` : '-'}</span>
            </div>
          </div>
        </div>
        {/* 버튼 제거 - 뉴시즈만 수동으로 체크아웃 함 */}
        {/* <div className="check-out-button-container">
          <span className="check-out-button" onClick={openModalCheckout}>
            모바일 체크아웃 하기
          </span>
        </div> */}
      </div>
      <MobilekeyMenual isOpen={isOpenModalMobilekeyMenual} onClose={closeModalMobilekeyMenual} />
      <CheckOut isOpen={isOpenModalCheckOut} onClose={closeModalCheckOut} checkOut={checkOut} />
    </Fragment>
  );
};

export default BookingList;
