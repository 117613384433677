import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import MainLayout from "../components/layouts/MainLayout";
import InitialBooking from "../components/views/BookingList";
import CheckedInBooking from "../components/views/CheckedInBooking";
import ModalError from "../components/ModalError";
import Spinner from "../components/Spinner";
import { bookingListAction } from "../stores/actions";
import * as api from "../api/sanha";
import * as serverApi from "../api/server";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RootState } from "../stores/reducers";
import { isoStringFormatter } from "../lib/formatter";

type Props = JSX.IntrinsicAttributes & { children?: React.ReactNode };

const InitialBookingContainer = (props: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModalError, setIsOpenModalError] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState("");
  const [modalErrorSubMessage, setModalErrorSubMessage] = useState("");
  const [qrCode, setQrCode] = useState("");

  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const { bookingItem, roomInfo } = useSelector(
    (state: RootState) => state.bookingList
  );

  const fetchBookingData = async () => {
    try {
      setIsLoading(true);
      const response = await serverApi.listBooking({
        rsvnNo: searchParams.get("rsvnNo"),
        rsvnSeqNo: "1",
      });

      if (response.code === "3025") {
        navigation("/frontInfo");
        return;
      }
      if (response.code !== "0000") {
        handleError(
          "예약 조회에 실패 하였습니다.",
          `${response.code}, ${response.message}`
        );
      }

      if (response.data) {
        dispatch(bookingListAction.setBookingItem(response.data));
        if (response.data.rsvnStatusCode === "CI") {
          dispatch(bookingListAction.setRoomInfo(response.pms_booking));

          const start_at = isoStringFormatter(
            response.data.arrvDate,
            response.data.checkin_hour
          );
          const end_at = isoStringFormatter(
            response.data.deptDate,
            response.data.checkout_hour
          );
          const qrResponse = await serverApi.getQRcode({
            start_at,
            end_at,
          });
          setQrCode(qrResponse.qr_data);
        }
      } else {
        dispatch(bookingListAction.setBookingItem({}));
        dispatch(bookingListAction.setRoomInfo({}));
      }
    } catch (error: any) {
      dispatch(bookingListAction.setBookingItem({}));
      dispatch(bookingListAction.setRoomInfo({}));
      handleApiError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCheckOut = async () => {
    try {
      setIsLoading(true);
      const folioNo = bookingItem.folioNo || roomInfo.folioNo;

      const { code, data, message } = await api.confirmCheckOutAmount({
        folioNo,
      });
      if (!["0000", "3024"].includes(code)) {
        handleError("퇴실 잔액 조회에 실패 하였습니다.", message);
        return;
      }
      if (data.balanceExist === "Y" && data.balanceAmount > 0) {
        handleError(
          "결제 금액이 남아 있어, 체크아웃에 실패 하였습니다.",
          "프론트로 퇴실 처리 문의 바랍니다."
        );
        return;
      }

      const { code: checkOutCode, message: checkOutMessage } =
        await api.checkOut({ folioNo, earlyCheckoutYN: "Y" });
      if (checkOutCode !== "0000") {
        const errorMessage =
          checkOutCode === "3011"
            ? "퇴실일자를 확인하시기 바랍니다. 체크아웃은 당일만 가능합니다."
            : checkOutMessage;
        handleError("체크아웃에 실패 하였습니다.", errorMessage);
        return;
      }

      navigation("/checkout/payment/success");
    } catch (error: any) {
      handleApiError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleError = (modalMessage: string, customMessage: string) => {
    setModalErrorMessage(modalMessage);
    throw new Error(customMessage);
  };

  const handleApiError = (error: any) => {
    setModalErrorMessage(
      error.response?.data?.message || "알 수 없는 오류가 발생했습니다."
    );
    setModalErrorSubMessage(error.message);
    openModalError();
  };

  const openModalError = () => {
    setIsOpenModalError(true);
  };

  const closeModalError = () => {
    setIsOpenModalError(false);
  };

  useEffect(() => {
    fetchBookingData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div {...props}>
      {!_.isEmpty(bookingItem) && bookingItem.rsvnStatusCode === "CI" ? ( // 체크인, 투숙 조회 api 완료되면 원복
        <MainLayout
          customStyle={{ position: "relative", margin: 0 }}
          ContentBody={
            <CheckedInBooking
              bookingItem={{ ...bookingItem, ...roomInfo }}
              qrCode={qrCode}
              checkOut={handleCheckOut}
            />
          }
        />
      ) : (
        <MainLayout
          ContentBody={
            <InitialBooking
              bookingItem={bookingItem}
              setModalErrorMessage={setModalErrorMessage}
              setModalErrorSubMessage={setModalErrorSubMessage}
              openModalError={openModalError}
            />
          }
        />
      )}
      <ModalError
        isOpen={isOpenModalError}
        message={modalErrorMessage}
        subMessage={modalErrorSubMessage}
        onClose={closeModalError}
      />
      <Spinner isLoading={isLoading} />
    </div>
  );
};

export default InitialBookingContainer;
